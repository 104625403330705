<template>
  <form class="w-screen max-w-md px-13 py-14 xl:flex xl:flex-1 xl:flex-col xl:justify-between xl:w-full xl:max-w-full only-xl:p-7-5 only-md:p-7-5 sm:p-4" @submit.prevent="requestPasswordReset">
    <div>
      <div class="icon-container absolute -mt-24 xl:relative xl:mt-10">
        <IconPassword class="w-44 h-44 xl:w-24 xl:h-24"/>
      </div>
      <div class="mt-12 text-xl38 font-bold xl:text-xl24">
        {{ $t('password.forgot') }}
      </div>
      <div class="mt-6">
        <input ref="emailRef" v-model="email" :placeholder="$t('email.enter_address')" data-testid="input-enter-address" type="email" class="v2 text-xl20 text-center py-5-8 md:py-2-4"/>
      </div>
    </div>

    <HCaptcha
      ref="hCaptchaRef"
      @onChallengeExpired="onClose"
      @onClosed="onClose"
      @onError="onError"
      @onExpired="onClose"
      @onVerify="onVerify"
    />

    <div class="mt-18">
      <ButtonV2
        testId="btn-new_request"
        :inactive="isLoading || !email"
        :label="$t('password.new_request')"
        submit
        wide
      />
    </div>
  </form>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2';
import HCaptcha from '@/components/misc/HCaptcha';
import IconPassword from '@/assets/icons/keys.svg';
import REQUEST_PASSWORD_RESET from '@/graphql/mutations/RequestPasswordReset.gql';

import { isApiError } from '@/utils/error-handler';

export default {
  name: 'ForgotPassword',

  components: {
    ButtonV2,
    HCaptcha,
    IconPassword,
  },

  data: function () {
    return {
      email: '',
      captcha: '',
      isLoading: false,
    };
  },

  async mounted() {
    await this.$store.dispatch('events/track', {
      event: 'FORGOT_PASSWORD_VIEWED',
    });
    this.$nextTick(() => {
      this.$refs.emailRef.focus();
    });
  },

  methods: {
    onClose() {
      this.isLoading = false;
    },

    onError() {
      this.isLoading = false;
    },

    onSubmit() {
      this.$refs.hCaptchaRef.execute();
    },

    async onVerify(captcha) {
      this.captcha = captcha;
      await this.requestPasswordReset();
    },

    async requestPasswordReset() {
      const { email, captcha, } = this.$data;

      try {
        this.isLoading = true;

        await this.apolloApiCall({
          mutation: REQUEST_PASSWORD_RESET,
          variables: {
            email,
            captcha,
          }
        });
        await this.$store.dispatch('events/track', {
          event: 'FORGOT_PASSWORD_SUBMITTED',
        });
        this.showSuccess(this.$t('success.forgot_password_submitted'));
        this.hideModal();
      } catch (err) {
        if (isApiError(err)) {
          if (err['error_code'] === 'invalid_captcha') {
            return this.onSubmit();
          }
        }

        this.isLoading = false;
        await this.showError(err);
      }
    },
  },
};
</script>

<style scoped>
  .modal-container {
    overflow-y: initial !important;
  }
  .icon-container {
    @apply top-0 right-0 left-0 flex justify-center;
  }
</style>
